<template>
    <onboarding-layout
        :title="title"
        :error-text="errorText"
        :loading="loading"
        data-testid="additional-document-verification-page"
    >
        <upload-button
            class="mb-2"
            title="Other Document"
            @on-file-change="uploadOtherDocument($event, 1)"
            :is-complete="hasDocumentUploaded(1)"
            :is-submitting="isDocumentUploading(1)"
            :disabled="!!currentlyUploadingIndex"
            incomplete-text="Add"
            complete-text="✓ Added"
            accept="image/*,application/pdf"
        />
        <template v-for="(uploadButton, index) in uploadFileList">
            <upload-button
                :key="`upload-button-${index + 2}`"
                class="mb-2"
                title="Add More (Optional)"
                @on-file-change="uploadOtherDocument($event, index + 2)"
                :is-complete="hasDocumentUploaded(index + 2)"
                :is-submitting="isDocumentUploading(index + 2)"
                :disabled="!!currentlyUploadingIndex"
                incomplete-text="Add"
                complete-text="✓ Added"
                accept="image/*,application/pdf"
            />
        </template>

        <form-button
            type="button"
            label="Continue"
            class="mt-2"
            :block="true"
            @click="next"
            :submitting="submitting"
            event-name="click_button_continue_verify_supporting_document"
            data-testid="supporting-document-verification-page_continue-button"
        />

        <template #sidebar>
            <ul class="list-unstyled list-row">
                <feedback-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import originationMixin from '@/mixins/originationMixin'
    import { mixins } from '@/mixins/mixins'
    import { i18n } from '@/utils/i18n'
    import FormButton from '@/components/base/FormButton'
    import UploadButton from '@/components/UploadButton'
    import { documentUpdateStatus, getApplicantReturningToDocumentUploadPortal, uploadDocument } from '@/services/api'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import computePreQualificationOfferFromStorageMixin from '@/mixins/computePreQualificationOfferFromStorageMixin'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import documentVerificationMixin from '@/mixins/documentVerificationMixin'

    export default {
        name: 'SupportingDocumentVerification',
        props: {
            returnToken: String,
        },
        components: {
            FeedbackListItem,
            OnboardingLayout,
            FormButton,
            UploadButton,
        },
        data: function () {
            return {
                returnToken2: this.$route.query?.returnToken2 || this.returnToken,
                title: '',
            }
        },
        mixins: [mixins, originationMixin, documentVerificationMixin, computePreQualificationOfferFromStorageMixin, experimentsMixin],
        mounted: async function () {
            this.loading = true
            let previousUploadInfo = appSessionStorage.getItem(sessionStorageKey.supportingDocument)
            if (previousUploadInfo) {
                this.uploadFileList = JSON.parse(previousUploadInfo).map((info) => !!info)
            } else {
                appSessionStorage.setItem(sessionStorageKey.supportingDocument, JSON.stringify([]))
                this.uploadFileList = []
            }

            try {
                if (appSessionStorage.getItem(sessionStorageKey.jwtTokens) && appSessionStorage.getItem(sessionStorageKey.returnToken2) !== this.returnToken2) {
                    logger.info(`User hit document JODL page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    return reloadPageWithCleanState()
                }

                if (!appSessionStorage.getItem(sessionStorageKey.loanApplicationId)) {
                    // Fresh reload, get everything
                    appSessionStorage.setItem(sessionStorageKey.returnToken2, this.returnToken2)
                    const applicantReturningResponse = await getApplicantReturningToDocumentUploadPortal(this.returnToken2)

                    if (applicantReturningResponse.data.error === 'APPLICANT_DOES_NOT_EXIST_ERROR') {
                        logger.info(`Applicant does not exist`)
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identity' } })
                    }

                    if (applicantReturningResponse.data.error === 'LOAN_APPLICATION_DOES_NOT_EXIST_ERROR') {
                        logger.info(`Loan Application does not exist`)
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'identity' } })
                    }

                    if (applicantReturningResponse.data.error === 'ERROR_BLOCKED_FROM_DOCUMENT_UPLOAD_PORTAL') {
                        logger.info(`User is blocked from using the document upload portal`)
                        return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'default' } })
                    }

                    logger.info(`Received get applicant returning response upon JODL document retrieval: ${JSON.stringify(applicantReturningResponse.data)}`)
                }
                this.title = i18n.t('supportingDocumentUpload.title', { firstName: this.verifyingApplicantName })
                this.$logEvent('view_other_document_verification')
            } catch (e) {
                logger.warn('Did not find an applicant that could upload a document', e)
                await this.$router.replace({
                    path: sharedPagePaths.THANKS,
                    query: { reason: 'identity' },
                })
            } finally {
                this.loading = false
            }
        },
        methods: {
            uploadOtherDocument: async function (fileList, index) {
                this.$logEvent('click_button_upload_supporting_document')
                this.errorText = ''
                try {
                    this.currentlyUploadingIndex = index
                    const file = fileList[0]
                    if (!file || !file.size) {
                        logger.warn('Supporting document file was not selected.')
                        this.errorText = i18n.tc('supportingDocumentUpload.errorSelecting')
                        this.scrollToTop()
                        return
                    }
                    const supportingDocumentFileInfo = this.getFileInfo(file)
                    const previousFileUploads = JSON.parse(appSessionStorage.getItem(sessionStorageKey.supportingDocument)) || []

                    // check if same file have already been uploaded
                    if (previousFileUploads.includes(supportingDocumentFileInfo)) {
                        logger.warn('Supporting document file already exists in storage')
                        this.errorText = i18n.tc('supportingDocumentUpload.errorDuplicate')
                        this.scrollToTop()
                        return
                    }

                    const response = await uploadDocument(file, 'supportingDocument', index, this.isCoApplicantVerifying)
                    if (response.data.error === 'ERROR_UNSUPPORTED_DOCUMENT_TYPE') {
                        this.errorText = i18n.tc('global.errors.unsupportedFileError')
                        this.scrollToTop()
                        return
                    }
                    this.uploadFileList[index - 1] = previousFileUploads[index - 1] = supportingDocumentFileInfo
                    appSessionStorage.setItem(sessionStorageKey.supportingDocument, JSON.stringify(previousFileUploads))
                } catch (error) {
                    logger.error(`Failed to upload supporting document`, error)
                    this.errorText = i18n.tc('supportingDocumentUpload.errorText')
                    this.scrollToTop()
                } finally {
                    this.currentlyUploadingIndex = null
                }
            },
            next: async function () {
                if (this.submitting) return

                if (this.uploadFileList.length <= 0) {
                    this.errorText = 'Please upload a file to continue'
                    return
                }

                this.submitting = true
                this.errorText = ''

                try {
                    const successfullyUpdatedStatus = await documentUpdateStatus('SUPPORTING_DOCUMENT')
                    if (successfullyUpdatedStatus.data.success) {
                        await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'uploadedDocs' } })
                    } else {
                        this.errorText = 'Something went wrong. Please refresh the page and try again.'
                    }
                } catch (error) {
                    logger.error('Error beginning supporting document verification', error)
                    this.errorText = ApiErrorHandler(error)
                    this.submitting = false
                }
            },
        },
    }
</script>
