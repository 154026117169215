import Vue from 'vue'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { startCase, toLower } from 'lodash'
import { logger } from '@/utils/logger'

export default Vue.extend({
    data: function () {
        return {
            currentlyUploadingIndex: null,
            uploadFileList: [],
        }
    },
    computed: {
        isCoApplicantVerifyingIncome() {
            if (appSessionStorage.getItem(sessionStorageKey.coApplicantJwtTokens)) {
                const statedIncome = parseInt(appSessionStorage.getItem(sessionStorageKey.statedIncome) as string)
                const coApplicantStatedIncome = parseInt(appSessionStorage.getItem(sessionStorageKey.coApplicantStatedIncome) as string)
                return statedIncome < coApplicantStatedIncome
            }
            return false
        },
        verifyingApplicantName() {
            if (!this.isCoApplicantVerifyingIncome) {
                return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.firstName) || undefined)) || ''
            } else {
                return startCase(toLower(appSessionStorage.getItem(sessionStorageKey.coApplicantFirstName) || undefined)) || ''
            }
        },
    },
    methods: {
        isDocumentUploading(index: number) {
            return this.currentlyUploadingIndex === index
        },
        hasDocumentUploaded(index: number) {
            return !!this.uploadFileList[index - 1]
        },
        getFileInfo: function (file: File) {
            const fileInfo = {
                name: file.name,
                size: file.size,
                lastModifiedDate: file.lastModified,
            }
            logger.log(`document fileInfo ${JSON.stringify({ ...fileInfo, type: file.type })}`)
            return JSON.stringify(fileInfo)
        },
        scrollToTop() {
            document.body.scrollTop = 0 // For Safari
            document.documentElement.scrollTop = 0
        },
    },
})
