import { capitalize } from 'lodash'

// Unlike lodash.startCase(), this does NOT remove special chars
// This DOES NOT capitalize letters that start after any punctuation, only after spaces
// Marco-arelious Merange, M'dangelo Kitkat K@t, etc
export const toTitleCaseSpaceStarts = (str: string): string => {
    if (!str) return ''
    return str.split(' ').map(capitalize).join(' ')
}

// Unlike lodash.startCase(), this does NOT remove special chars
// This DOES capitalize letters that start after any punctuation
// Marco-Arelious Merange, M'Dangelo Kitki!T K@T, etc
export const toTitleCasePunctuationStarts = (str: string): string => {
    if (!str) return ''
    return str.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}
