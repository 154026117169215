<template>
    <div>
        <form-container
            id="feedbackBox"
            ref="feedbackBox"
            v-if="!successfullySubmitted && !showSearchSupportField"
        >
            <form-field
                name="feedbackText"
                ref="feedbackText"
                class="border-primary"
                :placeholder="inputPlaceholder"
                :label="inputPlaceholder"
                validation-rules="required|min:2"
                inputmode="string"
                input-type="auto"
                :input-on-click="onSubmitFeedback"
                v-model="feedbackText"
            />
        </form-container>
        <form-container
            id="feedbackBoxWithSearch"
            ref="feedbackBoxWithSearch"
            v-if="!successfullySubmitted && showSearchSupportField"
        >
            <form-field-search-support
                input-placeholder="What is your question?"
                :input-max-entries="3"
                :exclude-snippets="true"
                :inline-article="true"
                :show-feedback-button="true"
            />
        </form-container>
    </div>
</template>

<script>
    import FormContainer from '@/components/base/FormContainer'
    import { i18n } from '@/utils/i18n'
    import { sendUserFeedbackEmail } from '@/services/api'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import FormField from './base/FormField'
    import { logger } from '@/utils/logger'
    import FormFieldSearchSupport from '@/components/base/FormFieldSearchSupport'

    export default {
        name: 'FeedbackBox',
        components: {
            'form-field-search-support': FormFieldSearchSupport,
            'form-field': FormField,
            'form-container': FormContainer,
        },
        props: {
            inputPlaceholder: { type: String, default: i18n.t('components.feedbackBox.placeholder') },
            showSearchSupportField: { type: Boolean, default: false },
        },
        data: function () {
            return {
                feedbackText: '',
                submitting: false,
                successfullySubmitted: false,
            }
        },
        methods: {
            onSubmitFeedback: async function () {
                this.submitting = true
                try {
                    const isValid = await this.$refs.feedbackBox.$refs.observer.validate()
                    if (isValid) {
                        await sendUserFeedbackEmail(this.feedbackText, appSessionStorage.getItem(sessionStorageKey.applicantId))
                        this.$logEvent('event_successfully_sent_feedback', { feedback: this.feedbackText })
                        this.successfullySubmitted = true
                        this.$emit('feedback-sent')
                    } else {
                        // Note that the call to validate() above will display an error message
                        // if the form is invalid.
                        this.$logEvent('event_failed_to_send_feedback', {
                            error: `invalid-text: ${this.feedbackText}`,
                        })
                    }
                } catch (e) {
                    this.$refs.feedbackText.applyError(i18n.t('global.errors.generic'))
                    logger.error(`Failed to send feedback message: ${this.feedbackText}. Error: ${e.message}`)
                    this.$logEvent('event_failed_to_send_feedback', {
                        error: `error: ${e}`,
                    })
                } finally {
                    this.submitting = false
                }
            },
        },
    }
</script>

<style scoped>
    /* Makes a micro adjustment so the message appears vertically aligned with the icon */
    .success-message-title {
        line-height: 1.5rem;
    }
</style>
