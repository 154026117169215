<template>
    <div class="layout p-0">
        <div
            v-if="loading"
            class="vh-100 px-1"
        >
            <div v-if="errorText">
                <div
                    class="alert-container pt-3"
                    v-show="errorText.length > 0"
                    data-testid="container-error-text"
                >
                    <div
                        class="alert alert-warning text-center"
                        role="alert"
                    >
                        <span v-html="errorText" />
                    </div>
                </div>
            </div>
            <loading-indicator :title="loadingTitle" />
        </div>
        <div
            v-show="!loading"
            class="onboarding-layout-container"
        >
            <div class="left-container">
                <div class="left-content">
                    <div class="left-header">
                        <img
                            src="@/assets/images/global/aven.svg"
                            class="logo"
                            width="71"
                            alt="Go Home"
                        >
                        <section-header
                            class="small text-muted"
                            v-if="stepTitle"
                        >
                            {{ stepTitle }}
                        </section-header>
                        <button
                            class="pif-background small rounded-3 border-0 px-1 pif-button-py"
                            v-if="pifEnabled"
                            @click="togglePifBonusReminder"
                        >
                            {{ $t('components.onboarding.pifBonus.button', { formattedPifBonus: this.formattedPifBonus }) }}
                        </button>
                    </div>
                    <div
                        class="alert-container mb-3"
                        v-show="errorText.length > 0"
                    >
                        <div
                            class="alert alert-warning text-center"
                            role="alert"
                        >
                            <span v-html="errorText" />
                        </div>
                    </div>
                    <div
                        v-show="!loading && header && (title || subTitle)"
                        class="onboarding-layout-header mb-4"
                    >
                        <h5
                            data-testid="onboarding-title"
                            class="fw-light"
                            v-if="title"
                            v-html="title"
                        />
                        <p
                            v-if="subTitle"
                            class="fw-bold mt-5 my-5 text-center"
                            id="subTitle"
                            @click="submitSubtitleClick"
                        >
                            <span
                                style="cursor: pointer"
                                class="clickable-text"
                                v-html="subTitle"
                            />
                        </p>
                    </div>
                    <div
                        v-if="pifEnabled && pifBonusReminderEnabled"
                        class="pif-background p-2 d-flex rounded-2 mb-5"
                    >
                        <img
                            class="me-1"
                            src="@/assets/images/origination/heart-small.svg"
                            alt="love"
                        >
                        <div>
                            <h6 class="mb-0">
                                {{ $t('components.onboarding.pifBonus.title', { formattedPifBonus: this.formattedPifBonus }) }}
                            </h6>
                            {{ $t('components.onboarding.pifBonus.body', { formattedPifBonus: this.formattedPifBonus, pifSenderName: this.pifSenderName }) }}
                        </div>
                    </div>
                    <slot />
                </div>
            </div>
            <div class="right-container">
                <div class="right-content">
                    <slot name="sidebar">
                        <ul class="list-unstyled list-row">
                            <feedback-list-item />
                        </ul>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import SectionHeader from '../components/SectionHeader'
    import { sessionStorageKey } from '@/utils/storage'
    import FeedbackListItem from '@/components/onboarding/FeedbackListItem'
    import { experimentsMixin } from '@/mixins/experimentsMixin'

    export default {
        mixins: [experimentsMixin],
        data: function () {
            return {
                pifBonus: Number(sessionStorage.getItem(sessionStorageKey.pifBonus)),
                pifSenderName: sessionStorage.getItem(sessionStorageKey.pifSenderName),
                pifBonusReminderEnabled: this.showPifBonusReminder,
            }
        },
        computed: {
            formattedPifBonus: function () {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
                    .format(this.pifBonus)
                    .toString()
                    .replace('.00', '')
            },
            pifEnabled: function () {
                return !!this.pifBonus && !!this.pifSenderName
            },
        },
        name: 'OnboardingLayout',
        props: {
            header: { type: Boolean, default: true },
            stepTitle: { type: String, default: '' },
            title: { type: String },
            subTitle: { type: String },
            subTitleClick: { type: Function },
            loading: { type: Boolean },
            loadingTitle: { type: String, default: 'Loading...' },
            errorText: { type: String, default: '' },
            showPifBonusReminder: { type: Boolean, default: false },
        },
        components: {
            FeedbackListItem,
            'section-header': SectionHeader,
            'loading-indicator': LoadingIndicator,
        },
        methods: {
            submitSubtitleClick: function () {
                this.$emit('sub-title-click')
            },
            togglePifBonusReminder: function () {
                this.pifBonusReminderEnabled = !this.pifBonusReminderEnabled
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/layouts/onboarding';
</style>
