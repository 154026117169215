<template>
    <div>
        <div
            v-if="isDevMode"
            class="top-bar"
        >
            <a
                href="#"
                @click="downloadPdf"
            > Download PDF version </a>

            <div>
                <label for="returnToken"> Return Token </label>
                <input
                    id="returnToken"
                    v-model="returnToken2"
                    type="text"
                >
            </div>

            <div>
                <label for="docType"> Doc Type </label>
                <input
                    id="docType"
                    v-model="docType2"
                    type="text"
                >
            </div>

            <div>
                <button @click="reloadDocs">
                    Reload Docs
                </button>
            </div>
        </div>
        <div
            v-show="loading"
            class="vh-100 px-1"
        >
            <loading-indicator />
        </div>
        <!-- need to use v-show so we can set the documentHtml before loading is set ot false -->
        <div
            v-show="!loading"
            class="p-2"
        >
            <div
                id="iframeWrapperDiv"
                ref="iframeWrapper"
            >
                <!-- an iframe will be inserted here -->
            </div>
        </div>
    </div>
</template>

<script>
    import DOMPurify from 'dompurify'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    import { getApplicantReturning } from '@/services/api'
    import { getHtmlDocumentForApplicant } from '@/utils/document'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { getDocumentForApplicantAndOpen } from '../../utils/document'

    export default {
        name: 'JodlDocument',
        components: { LoadingIndicator },
        props: {
            docType: String,
            returnToken: String,
        },
        data: function () {
            return {
                loading: true,
                returnToken2: this.$route.query?.returnToken2 || this.returnToken,
                docType2: this.$route.query?.docType2 || this.docType,
            }
        },
        computed: {
            isDevMode() {
                return this.$route.fullPath.includes('devDocReturn')
            },
        },
        mounted: async function () {
            this.$logEvent('view_jodl_document', {
                docType: this.docType2,
                returnToken: this.returnToken2,
            })

            await this.reloadDocs()
        },
        methods: {
            async downloadPdf() {
                await getDocumentForApplicantAndOpen(this.docType2)
            },
            async reloadDocs() {
                this.loading = true
                try {
                    if (!this.returnToken2) {
                        // We can't do anything without the return token
                        if (this.isDevMode) {
                            console.log('No return token has been provided')
                        } else {
                            logger.warn('Applicant hit document page without return token')
                        }
                        throw new Error('No return token has been provided')
                    }

                    // If we are in dev mode add the params to the query so we don't lose state if we reload
                    if (this.isDevMode) {
                        const query = {
                            ...this.$route.query,
                            returnToken2: this.returnToken2 || this.$route.query?.returnToken2,
                            docType2: this.docType2 || this.$route.query?.docType2,
                        }
                        await this.$router.replace({ query })
                    }

                    if (appSessionStorage.getItem(sessionStorageKey.jwtTokens) && appSessionStorage.getItem(sessionStorageKey.returnToken2) !== this.returnToken2) {
                        logger.info(`User hit document JODL page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                        return reloadPageWithCleanState()
                    }

                    if (!appSessionStorage.getItem(sessionStorageKey.loanApplicationId)) {
                        // Fresh reload, get everything
                        appSessionStorage.setItem(sessionStorageKey.returnToken2, this.returnToken2)
                        const applicantReturningResponse = await getApplicantReturning(this.returnToken2)
                        logger.info(`Received get applicant returning response upon JODL document retrieval: ${JSON.stringify(applicantReturningResponse.data)}`)
                    }

                    if (this.docType2) {
                        const htmlLegalDocument = await getHtmlDocumentForApplicant(this.docType2)
                        const iframeWrapper = document.getElementById('iframeWrapperDiv')
                        if (typeof htmlLegalDocument.data === 'string') {
                            const sanitized = DOMPurify.sanitize(htmlLegalDocument.data, { WHOLE_DOCUMENT: true })
                            const topBarHeight = this.isDevMode ? '45px' : '0px'
                            const f1 = document.createElement('iframe')
                            f1.name = 'f1'
                            // necessary styling
                            f1.style.position = 'absolute'
                            f1.style.height = `calc(100% - ${topBarHeight})`
                            f1.style.width = '100%'
                            f1.style.top = topBarHeight
                            f1.style.left = '0'
                            // Reset state
                            iframeWrapper.innerHTML = ''
                            iframeWrapper.appendChild(f1)
                            const frameDoc = f1.contentWindow || f1.contentDocument.document || f1.contentDocument
                            frameDoc.document.write(sanitized)
                            frameDoc.document.close()
                        } else if (this.isDevMode) {
                            iframeWrapper.innerHTML = '<h1>Error loading the document!</h1>'
                        } else {
                            this.$router.replace({
                                path: sharedPagePaths.THANKS,
                                query: {
                                    reason: 'jodlDocument',
                                },
                            })
                        }
                    }
                } catch (error) {
                    logger.error('Could not fetch using document JODL', error)
                    if (this.isDevMode) {
                        console.error(error)
                    } else {
                        await this.$router.replace({
                            path: sharedPagePaths.THANKS,
                            query: {
                                reason: 'jodlDocument',
                            },
                        })
                    }
                }
                this.loading = false
            },
        },
    }
</script>

<style scoped>
    .top-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        width: 100%;
        background-color: #f9c415;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
</style>
