<template>
    <li class="g-0 row mb-2">
        <div class="col-auto">
            <img
                class="list-item-icon"
                src="@/assets/images/global/icon-messages-bubble-square.svg"
                alt="Close quickly"
            >
        </div>
        <div class="col text-start">
            <p class="fw-bold mb-0 mt-1">
                {{ $t('components.onboarding.feedbackListItem.title') }}
            </p>
            <p
                v-if="showFeedbackBox"
                class="text-muted"
            >
                {{ showSearchSupportField ? 'Ask a question or send feedback.' : 'Send a message – we’re here to help.' }}
            </p>
            <div
                v-else
                class="text-muted"
            >
                {{ $t('components.onboarding.helpListItem.altSubTitle') }}
            </div>
            <feedback-box
                v-show="showFeedbackBox"
                @feedback-sent="handleFeedbackSent"
                :show-search-support-field="showSearchSupportField"
            />
        </div>
    </li>
</template>

<script>
    import { contact_info } from '@/utils/contact-info-dictionary'
    import FeedbackBox from '../FeedbackBox'
    export default {
        name: 'FeedbackListItem',
        components: {
            'feedback-box': FeedbackBox,
        },
        props: {
            showSearchSupportField: { type: Boolean, default: false },
        },
        data: function () {
            return {
                showFeedbackBox: true,
            }
        },
        methods: {
            handleFeedbackSent: function () {
                this.showFeedbackBox = false
            },
        },
        computed: {
            contactInfo: function () {
                return contact_info
            },
        },
    }
</script>

<style scoped>
    @import '../../styles/components/listItem.scss';
</style>
